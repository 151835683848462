import './fonts.css';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import '@rmwc/button/styles';
import '@rmwc/typography/styles';
import '@rmwc/card/styles';
import '@rmwc/icon/styles';
import '@rmwc/circular-progress/styles';
import '@rmwc/textfield/styles';
import '@rmwc/menu/styles';

import './index.css';
import Loader from './Loader';

const ScheduleWidget = React.lazy(() => import('./ScheduleWidget'));

if (process.env.REACT_APP_FIREBASE_ENV === 'production') {
  const script = document.createElement('script');

  script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.GAPI_KEY}`;
  script.async = true;

  document.body.appendChild(script);

  const g_script = document.createElement('script');

  g_script.text = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${process.env.GAPI_KEY}');`;
  g_script.async = true;

  document.body.appendChild(g_script);
}

const element = document.getElementById('schedule-widget');

if (element.getAttribute('data-mode') === 'week') {
}

ReactDOM.render(
  <Suspense fallback={<Loader />}>
    <ScheduleWidget
      defaultPage={
        element.getAttribute('data-mode') === 'week'
          ? 'book-appt-date'
          : 'book-appt-today'
      }
    />{' '}
  </Suspense>,
  element
);
