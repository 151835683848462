import BeatLoader from 'react-spinners/BeatLoader';
import { Typography } from '@rmwc/typography';

const Loader = ({ showText, text }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <BeatLoader color="var(--bluesleep)" size={10} />
      {showText && (
        <Typography
          tag="div"
          use="body2"
          style={{
            color: 'var(--bluesleep)',
            fontSize: '18px',
            textAlign: 'center',
            fontFamily: 'Poppins',
            marginTop: 16,
          }}
        >
          {text}
        </Typography>
      )}
    </div>
  );
};

export default Loader;
